@import 'assets/colors';

$inputFontSize: '1.1em';

body {
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100vh;
  overflow: auto;
  //background-color: #fafafa;
  background-color: rgba(1, 19, 52, 0.87);
  transition: background-image .5s ease-in-out, background-position .5s ease-in-out;
}

.pointer {
  cursor: pointer;
}

/* SVG */
td > svg {
  -webkit-transform: translateY(15%);
  -ms-transform: translateY(15%);
  transform: translateY(15%);
}

div.MuiSelect-select > svg {
  -webkit-transform: translateY(15%);
  -ms-transform: translateY(15%);
  transform: translateY(15%);

}

li > svg {
  margin-top: -6px;
  margin-right: 4px;
}

p svg {
  -webkit-transform: translateY(25%);
  -ms-transform: translateY(25%);
  transform: translateY(25%);
}

div.MuiSelect-select svg {
  font-size: 1.1em;
}

/* SVG end */

.font75 {
  font-size: 75%
}

.font85 {
  font-size: 85%
}

.fontBold {
  font-weight: bold;
}

/* Select dropdown li elements */
.MuiButtonBase-root.MuiMenuItem-root {
  padding-top: 1px;
  padding-bottom: 1px;
}

/* Paginator */
.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.MuiTablePagination-toolbar {
  padding-top: 0;
  padding-bottom: 0;
  min-height: 36px;
  height: 36px;
}

.MuiTablePagination-root .MuiButtonBase-root {
  padding-top: 0;
  padding-bottom: 0;
}


/* Utils */

span.cut_end:after {
  color: rgba(1, 19, 52);
  content: "...";
  font-size: 50%
}


/* Data grid filter */
.datagrid-filter-box label.MuiInputLabel-formControl {
  //font-size: 0.85em;
  font-size: $inputFontSize;
}

/* Input labels */

._InputLabel {
  //font-size: 0.85em;
  font-size: $inputFontSize;
}

._InputLabel,
label.MuiFormControlLabel-root .MuiFormControlLabel-label, // switch
label.MuiFormLabel-root.MuiInputLabel-root,
label.MuiInputLabel-formControl {
  text-transform: capitalize;
}

._InputLabel:not(.Mui-error),
label.MuiFormControlLabel-root .MuiFormControlLabel-label, // switch
label.MuiFormLabel-root.MuiInputLabel-root:not(.Mui-error),
label.MuiInputLabel-formControl[data-shrink="true"]:not(.Mui-error) {
  font-weight: bold;
  color: $darkBlue;
}

label.MuiInputLabel-formControl[data-shrink="false"]:not(.Mui-error),
{
  font-weight: normal;
  color: $darkBlueLighter;
}

.Mui-disabled {
  -webkit-text-fill-color: #7c7c7c !important;
}

.MuiAutocomplete-popupIndicator:hover,
.MuiAutocomplete-popupIndicator svg,
.MuiSelect-icon {
  display: none;
}

.dtp_mui input.MuiInputBase-inputSizeSmall {
  font-size: 90%;
  min-height: 1.6em;
}

.uppercase {
  text-transform: uppercase;
}


// Charts
.charts-controls button,
.charts-controls .MuiInputBase-root {
  font-size: 11px !important;
}

.charts-controls #Brands-label {
  line-height: 2.3em;
}

.charts-controls label,
.charts-controls label.MuiFormControlLabel-root,
{
  font-size: 12px !important;
}

.charts-controls label span.MuiFormControlLabel-label, {
  font-size: 9px !important;
}


// LOGIN PAGE

.login-wrapper-css .MuiPaper-rounded .MuiInputLabel-root.MuiInputLabel-formControl {
  color: white;
  background-color: #3d80f3;
  font-size: 140%;
}

.login-wrapper-css .MuiPaper-rounded {
  //background-color: #3d80f3; // !!
  background-color: #023180; // !!
  //background-color: rgba(1, 19, 52, 0.71);
}

.login-wrapper-css .MuiPaper-rounded fieldset.MuiOutlinedInput-notchedOutline.Mui-error {
  border-color: coral;
}

.login-wrapper-css .MuiPaper-rounded .MuiOutlinedInput-notchedOutline:hover,
.login-wrapper-css .MuiPaper-rounded .MuiOutlinedInput-notchedOutline {
  //border-color: white;
  border-color: #3d80f3;
}

.login-wrapper-css .MuiPaper-rounded .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  //border-color: #f6a98e;
  border-color: #3d80f3;
}

.login-wrapper-css .MuiPaper-rounded .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline:hover {
  //border-color: #f6a98e;
  border-color: #3d80f3;
}

.login-wrapper-css .MuiPaper-rounded input {
  color: white;
  font-size: 120%;
}

.login-wrapper-css {
  background-color: rgba(1, 19, 52, 0.87);
}

.login-wrapper-css {
  position: relative;
}

.login-wrapper-css::before {
  content: "";
  position: absolute;
  top: -22vh;
  left: 0;
  width: 100%;
  height: 100%;
  //background-image: url('/assets/images/sync1_logo_l.svg');
  background-image: url('/assets/images/sync_logo.svg');
  background-position: center;
  //background-size: cover;
  background-size: contain;
  //transform: scale(1, 1);
  background-repeat: no-repeat;
  z-index: -1;
}